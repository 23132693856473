import React, { useState } from "react";
import BackgroundImage from 'gatsby-background-image';
import Image from 'gatsby-image';
import Layout from "../share/layout";
import VideoIcon from '../../images/youtubeicon.svg'
import { useLocation } from "@reach/router"
import Modal from 'react-bootstrap/Modal';
import { Link } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ErrorImageBackground from "../../images/Error_Images/Insert_Image.png";
import ShareButton from "../share/ShareButton";

const PeruunsonidoTemplate = ({ pageContext }) => {
	let location = useLocation();

	const QueryResultData = pageContext.data;

	var normalize = (function () {
		var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç,",
			to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
			mapping = {};

		for (var i = 0, j = from.length; i < j; i++)
			mapping[from.charAt(i)] = to.charAt(i);

		return function (str) {
			var ret = [];
			for (var i = 0, j = str.length; i < j; i++) {
				var c = str.charAt(i);
				if (mapping.hasOwnProperty(str.charAt(i)))
					ret.push(mapping[c]);
				else if ((str[i] === " "))
					ret.push('-');
				else if ((str.length - 1 === i) && (str[str.length - 1] === " "))
					ret.push('-');
				else
					ret.push(c);
			}
			return ret.join('');
		}
	})();

	const [showModalUno, setShowModalUno] = useState(false);
	const [showModalDos, setShowModalDos] = useState(false);

	const ReturnBody = () => {
		return (
			<div className="gradient-image">
				<div className="template-container">
					<div className="template-subcontainer-1">
						<h1><span className="text-decoration"></span>{QueryResultData.tItulo}</h1>
						<div className="text-description-container">
							{QueryResultData.descripcion &&
								<>{documentToReactComponents(QueryResultData.descripcion.json)}</>
							}
						</div>
						<div className="videos-modal-container">
							<div className="videos-modal-subcontainer-1">
								{QueryResultData.tituloDeLaImagen1 &&
									<div className="title-video-modal">
										<div className="icon-container"><img src={VideoIcon} alt="imagen de icono de video" /></div>
										<div className="title-subcontainer">
											<p>{QueryResultData.tituloDeLaImagen1}</p>
										</div>
									</div>
								}
								{QueryResultData.imagenUno &&
									<div className={"video-image-modal " + (!(QueryResultData.enlaceDeVideo1) && 'disable-pointer')} onClick={() => { setShowModalUno(!showModalUno) }} role="button" tabIndex="0" onKeyDown={(ev) => { if (ev.keyCode === 13) { setShowModalUno(!showModalUno) } }}>
										<div className="gradient"></div>
										<Image fluid={QueryResultData.imagenUno.fluid} alt={QueryResultData.imagenUno.description ? QueryResultData.imagenUno.description : QueryResultData.imagenUno.title} />
									</div>
								}
							</div>
							<div className="videos-modal-subcontainer-2">
								{QueryResultData.tituloDeLaImagen2 &&
									<div className="title-video-modal">
										<div className="icon-container"><img src={VideoIcon} alt="imagen de icono de video" /></div>
										<div className="title-subcontainer">
											<p>{QueryResultData.tituloDeLaImagen2}</p>
										</div>
									</div>
								}
								{QueryResultData.imagenDos &&
									<div className={"video-image-modal " + (!(QueryResultData.enlaceDeVideo2) && 'disable-pointer')} onClick={() => { setShowModalDos(!showModalDos) }} role="button" tabIndex="0" onKeyDown={(ev) => { if (ev.keyCode === 13) { setShowModalDos(!showModalDos) } }}>
										<div className="gradient"></div>
										<Image fluid={QueryResultData.imagenDos.fluid} alt={QueryResultData.imagenDos.description ? QueryResultData.imagenDos.description : QueryResultData.imagenDos.title} />
									</div>
								}
							</div>
						</div>
						{(QueryResultData.mostrarDescripcionInferior === true && QueryResultData.descripcionInferior) &&
							<div className="description">
								<div className="description-icon"><i className="fa fa-music"></i></div>
								<div className="description-text-container">{documentToReactComponents(QueryResultData.descripcionInferior.json)}</div>
							</div>
						}
						<div className="template-subcontainer-2 template-subcontainer-2-responsive">
							<div className="template-list">
								{QueryResultData.ttuloDeLaLista === '-' ?
									null :
									<h2>{QueryResultData.ttuloDeLaLista}</h2>
								}
								<ul className={QueryResultData.ttuloDeLaLista === '-' ? "List-margin-top-out-title" : "List-margin-top-title"}>
									{
										pageContext.data.lista && pageContext.data.lista.map((lista, i) => (
											<li key={i}>
												<Link to={`/${normalize(QueryResultData.tItulo).toLocaleLowerCase().trim()}/${normalize(lista).toLocaleLowerCase().trim()}`}><span>{i + 1}</span> {lista}</Link>
											</li>
										))
									}
								</ul>
								{QueryResultData.descripcionDeLaLista &&
									<div className="description-list-container">
										{
											!QueryResultData.habilitarTercerCarrusel ?
												<h1 className="description-list "><span className="text-decoration"></span>{QueryResultData.descripcionDeLaLista}</h1>
												:
												<Link className="description-list" to={`/${normalize(QueryResultData.tItulo).toLocaleLowerCase().trim()}/creditos-${normalize(QueryResultData.tItulo).toLocaleLowerCase().trim()}`}><span className="text-decoration"></span>
													<h3>{QueryResultData.descripcionDeLaLista}</h3></Link>
										}
									</div>
								}
							</div>
						</div>
					</div>
					<div className="template-subcontainer-2">
						<div className="template-list">
							{QueryResultData.ttuloDeLaLista === '-' ?
								null :
								<h2>{QueryResultData.ttuloDeLaLista}</h2>
							}
							<ul className={QueryResultData.ttuloDeLaLista === '-' ? "List-margin-top-out-title" : "List-margin-top-title"}>
								{
									pageContext.data.lista && pageContext.data.lista.map((lista, i) => (
										<li key={i}>
											<Link to={`/${normalize(QueryResultData.tItulo).toLocaleLowerCase().trim()}/${normalize(lista).toLocaleLowerCase().trim()}`}><span>{i + 1}</span> {lista}</Link>
										</li>
									))
								}
							</ul>
							{QueryResultData.descripcionDeLaLista &&
								<div className="description-list-container">
									{
										!QueryResultData.habilitarTercerCarrusel ?
											<h1 className="description-list "><span className="text-decoration"></span>{QueryResultData.descripcionDeLaLista}</h1>
											:
											<Link className="description-list" to={`/${normalize(QueryResultData.tItulo).toLocaleLowerCase().trim()}/creditos-${normalize(QueryResultData.tItulo).toLocaleLowerCase().trim()}`}><span className="text-decoration"></span>
												<h3>{QueryResultData.descripcionDeLaLista}</h3></Link>
									}
								</div>
							}
						</div>
					</div>
					{(QueryResultData.mostrarDescripcionInferior === true && QueryResultData.descripcionInferior) &&
						<div className="description description-responsive">
							<div className="description-icon"><i className="fa fa-music"></i></div>
							<div className="description-text-container">{documentToReactComponents(QueryResultData.descripcionInferior.json)}</div>
						</div>
					}
				</div>
			</div>
		)
	}

	return (
		<Layout
			descritpion={pageContext.data.subtitulo}
			title={pageContext.data.tItulo}
			subtitile={pageContext.data.subtitulo}
			imgurl={pageContext.data.imagenDeFondo.file.url}
			metaTitle={pageContext.data.metaTitle}
			metaKeywords={pageContext.data.metaKeywords}
			metaDescripcin={pageContext.data.metaDescripcin}
		>

			<div className="template-type-1 template-style-1">
				{QueryResultData.enlaceDeVideo1 &&
					<Modal show={showModalUno} onHide={() => { setShowModalUno(!showModalUno) }}>
						<Modal.Header closeButton>
							<Modal.Title>{QueryResultData.tituloDeLaImagen1}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<iframe width="100%" height="100%" src={QueryResultData.enlaceDeVideo1} title={pageContext.data.tItulo}></iframe>
						</Modal.Body>
					</Modal>
				}
				{QueryResultData.tituloDeLaImagen2 &&
					<Modal show={showModalDos} onHide={() => { setShowModalDos(!showModalDos) }}>
						<Modal.Header closeButton>
							<Modal.Title>{QueryResultData.tituloDeLaImagen2}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<iframe width="100%" height="100%" src={QueryResultData.enlaceDeVideo2} title={pageContext.data.tItulo}></iframe>
						</Modal.Body>
					</Modal>
				}

				<ShareButton url={location.pathname} />

				{QueryResultData.imagenDeFondo ?
					(<BackgroundImage className="background-image" fluid={QueryResultData.imagenDeFondo.fluid}>
						<ReturnBody />
					</BackgroundImage>)
					:
					(
						<div>
							<div style={{ backgroundImage: `url(${ErrorImageBackground})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} className="background-image error">
								<ReturnBody />
							</div>
						</div>
					)
				}
			</div>
		</Layout>
	)
}
export default PeruunsonidoTemplate
